import React, { Dispatch, Fragment, SetStateAction } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { PrimaryBtn } from "components/library"
import { Status } from "./component"

type P = {
	responseErr: string
	setStatus: Dispatch<SetStateAction<Status>>
}

export const ErrorModal = ({ responseErr, setStatus }: P) => {
	const { t } = useTranslation()

	return (
		<Transition.Child
			as={Fragment}
			enter="ease-out duration-300"
			enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
			enterTo="opacity-100 translate-y-0 sm:scale-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100 translate-y-0 sm:scale-100"
			leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		>
			<div className="bg-gray-50 rounded-lg text-left shadow-xl sm:max-w-lg w-full p-16">
				<div className="flex flex-col gap-6">
					<Dialog.Title
						as="h3"
						className="text-4xl font-medium text-gray-900 text-center"
					>
						{responseErr}
					</Dialog.Title>
					<div className="flex flex-col gap-4">
						<PrimaryBtn
							onClick={() => {
								setStatus("pending")
							}}
							type="submit"
						>
							{t("common.cta.okay")}
						</PrimaryBtn>

						<p className="text-xs text-center">
							<Trans
								i18nKey="download-document.message"
								components={{
									anchor: <Link to="/privacy-policy" />,
								}}
							/>
						</p>
					</div>
				</div>
			</div>
		</Transition.Child>
	)
}
