import React, {
	Dispatch,
	Fragment,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { EMAIL_PATTERN } from "utils/validation"
import { downloadDocument, DownloadDocumentBody } from "services"
import { SuccessModal } from "./success-modal"
import { ErrorModal } from "./error-modal"
import { PendingModal } from "./pending-modal"
import { VerificationModal } from "./verification-modal"

type P = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	file: string
	document: string
	documentName: string
	afterSubmit?: (email: string) => void
	showMoreDetails?: boolean
}

export const InitialData = {
	organization: "",
	job_title: "",
	join_newsletter: false,
}
export type Status = "pending" | "success" | "error" | "otp-sent"

export const DownloadDocument = ({
	open,
	setOpen,
	file,
	document,
	documentName,
	afterSubmit,
	showMoreDetails,
}: P) => {
	const { t } = useTranslation()
	const { language } = useI18next();
	const cancelButtonRef = useRef<HTMLDivElement>(null)
	const [status, setStatus] = useState<Status>("pending")
	const [responseErr, setResponseErr] = useState<string | null>(null)
	const [isEmailError, setIsEmailError] = useState(false)
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState<string>("")
	const [weChatId, setWeChatId] = useState<string>("")
	const [data, setData] = useState(InitialData)
	const [otpSentMessage, setOtpSentMessage] = useState<string>("")

	const onSubmit = async () => {
		if (language !== "zh" && !EMAIL_PATTERN.test(email)) return setIsEmailError(true)

		// TRACKING DOCUMENT DOWNLOAD IF PROVIDED
		if (afterSubmit) {
			afterSubmit(email)
		}

		const body: DownloadDocumentBody = {
			document_type: `${documentName} Download`,
			document_name: document,
			organization: showMoreDetails ? data.organization : null,
			job_title: showMoreDetails ? data.job_title : null,
			join_newsletter: data.join_newsletter,
			download_link: file,
		}

		if(language === "zh") {
			body.wechat_id = weChatId;
			body.phone = phone;
		} else {
			body.email = email;
		}

		try {
			const data = await downloadDocument(body)

			if (data.result.status === "Failed") {
				setStatus("error")
				setResponseErr(data.result.message)
				return
			}
			if (!data.result.verification_required) {
				setStatus("success");
				const fileUrl = data?.result?.data?.download_link || file;
				window.open(fileUrl, "_blank")
				return
			}

			setStatus("otp-sent")
			setOtpSentMessage(data.result.message)
		} catch (err) {
			console.log({ err })
			setStatus("error")
			setResponseErr(t("common.messages.internal-server-error"))
		} finally {
			setEmail("")
			setData(InitialData)
		}
	}

	useEffect(() => {
		if (open) setStatus("pending")
	}, [open])

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog
				as="div"
				className="fixed z-50 inset-0 overflow-y-auto"
				initialFocus={cancelButtonRef}
				onClose={setOpen}
			>
				<div className="flex relative items-center p-6 justify-center min-h-screen text-center">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					{status === "pending" && (
						<PendingModal
							onSubmit={onSubmit}
							documentName={documentName}
							isEmailError={isEmailError}
							setIsEmailError={setIsEmailError}
							setEmail={setEmail}
							setData={setData}
							data={data}
							email={email}
							phone={phone}
							setPhone={setPhone}
							weChatId={weChatId}
							setWeChatId={setWeChatId}
							showMoreDetails={showMoreDetails}
						/>
					)}

					{status === "success" && (
						<SuccessModal documentName={documentName} setOpen={setOpen} />
					)}

					{status === "error" && responseErr && (
						<ErrorModal responseErr={responseErr} setStatus={setStatus} />
					)}

					{status === "otp-sent" && (
						<VerificationModal message={otpSentMessage} setOpen={setOpen} />
					)}
				</div>
			</Dialog>
		</Transition.Root>
	)
}
