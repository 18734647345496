import React, {
	ChangeEvent,
	Dispatch,
	FormEvent,
	Fragment,
	SetStateAction,
} from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Link, Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { TranslationKey } from "types/translation"
import { Checkbox, Input, PrimaryBtn } from "components/library"
import { InitialData } from "./component"

type P = {
	documentName: string
	onSubmit: () => void
	showMoreDetails?: boolean
	isEmailError: boolean
	setIsEmailError: Dispatch<SetStateAction<boolean>>
	email: string
	setEmail: Dispatch<SetStateAction<string>>
	data: typeof InitialData
	setData: Dispatch<SetStateAction<typeof InitialData>>
	phone: string | undefined
	setPhone: Dispatch<SetStateAction<string>>
	weChatId: string | undefined
	setWeChatId: Dispatch<SetStateAction<string>>
}

export const PendingModal = ({
	onSubmit,
	documentName,
	showMoreDetails,
	isEmailError,
	setIsEmailError,
	data,
	email,
	setData,
	setEmail,
	phone,
	setPhone,
	weChatId, 
	setWeChatId
}: P) => {
	const { t } = useTranslation();
	const { language } = useI18next();

	function handleEmailChange(e: ChangeEvent<HTMLInputElement>) {
		setEmail(e.target.value)
		setIsEmailError(false)
	}

	function handlePhoneChange(e: ChangeEvent<HTMLInputElement>) {
		setPhone(e.target.value)
	}

	function handleWeChatIdChange(e: ChangeEvent<HTMLInputElement>) {
		setWeChatId(e.target.value)
	}

	function handleDataChange(e: ChangeEvent<HTMLInputElement>) {
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
		const { name, checked } = e.target
		setData({ ...data, [name]: checked })
	}

	function handleOnSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault()
		onSubmit()
	}

	return (
		<Transition.Child
			as={Fragment}
			enter="ease-out duration-300"
			enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
			enterTo="opacity-100 translate-y-0 sm:scale-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100 translate-y-0 sm:scale-100"
			leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
		>
			<div className="bg-gray-50 rounded-lg text-left shadow-xl sm:max-w-lg w-full p-16">
				<form
					onSubmit={handleOnSubmit}
					name="Case Study Download Form"
					className="flex flex-col gap-6"
				>
					<Dialog.Title
						as="h3"
						className="text-4xl font-medium text-gray-900 text-center"
					>
						{t("common.words.download")}{" "}
						{
							t(
								`common.words.${documentName
									.toLowerCase()
									.split(" ")
									.join("-")}` as TranslationKey
							) as string
						}
					</Dialog.Title>
					<div className="flex flex-col gap-3">
						{language === "zh" ? (
							<>
								<div className="flex flex-col gap-2">
									<Input
										type="tel"
										value={phone}
										onChange={handlePhoneChange}
										pattern="[0-9]{11}"
										placeholder={`${t("common.words.phone")}*`}
										required
									/>
								</div>
								<div className="flex flex-col gap-2">
									<Input
										type="text"
										value={weChatId}
										onChange={handleWeChatIdChange}
										placeholder={`${t("common.words.wechat-id")}*`}
										required
									/>
								</div>
							</>
						)
							: (
								<div className="flex flex-col gap-2">
									<Input
										type="email"
										value={email}
										isError={isEmailError}
										onChange={handleEmailChange}
										placeholder={`${t("download-document.input-placeholder")}*`}
										required
									/>
									{isEmailError && (
										<span className="text-red-400 text-sm">
											{t("download-document.wrong-email")}
										</span>
									)}
								</div>
							)
						}
						{showMoreDetails && (
							<div>
								<Input
									name="organization"
									className="w-full"
									value={data.organization}
									onChange={handleDataChange}
									placeholder={`${t("common.words.organization")}*`}
									required
								/>
							</div>
						)}
						{showMoreDetails && (
							<div>
								<Input
									name="job_title"
									className="w-full"
									value={data.job_title}
									onChange={handleDataChange}
									placeholder={`${t("common.words.job-title")}*`}
									required
								/>
							</div>
						)}
						<div>
							<Checkbox
								name="join_newsletter"
								checked={data.join_newsletter}
								desc="I want to receive the latest news and updates from the company"
								onChange={handleCheckboxChange}
								placeholder={`${t("download-document.input-placeholder")}*`}
							/>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<PrimaryBtn type="submit">{t("common.words.download")}</PrimaryBtn>

						<p className="text-xs text-center">
							<Trans
								i18nKey="download-document.message"
								components={{
									anchor: <Link to="/privacy-policy" />,
								}}
							/>
						</p>
					</div>
				</form>
			</div>
		</Transition.Child>
	)
}
